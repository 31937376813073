var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('v-text-field',{attrs:{"placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders && _vm.orders.records,"loading":_vm.isLoading,"search":_vm.search,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedDate")(item.createdAt)))])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("currency")(item && item.products && item.products.length ? item.products.map(function (x) { return x.price; }).reduce(function (a, b) { return a + b; }) : 0,"R", 2, { thousandsSeparator: ",", decimalSeparator: "." })))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"color":item.status == 'new' ? 'primary' : 'success',"small":""}},[_vm._v(_vm._s(item.status))]),_c('v-chip',{staticClass:"ma-1",attrs:{"color":item.payment == 'complete' ? 'success' : 'secondary',"small":""}},[_vm._v(_vm._s(item.payment == "complete" ? "paid" : "pending"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-eye")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }