<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" placeholder="Search..."></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="orders && orders.records" :loading="isLoading" :search="search" class="elevation-1" transition="scale-transition">
        <template v-slot:item.createdAt="{ item }">
          <h5 color="primary">{{ item.createdAt | getFormattedDate }}</h5>
        </template>
        <template v-slot:item.totalAmount="{ item }">
          <h5 color="primary">{{ item && item.products && item.products.length ? item.products.map((x) => x.price).reduce((a, b) => a + b) : 0 | currency("R", 2, { thousandsSeparator: ",", decimalSeparator: "." }) }}</h5>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip class="ma-1" :color="item.status == 'new' ? 'primary' : 'success'" small>{{ item.status }}</v-chip>
          <v-chip class="ma-1" :color="item.payment == 'complete' ? 'success' : 'secondary'" small>{{ item.payment == "complete" ? "paid" : "pending" }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small color="primary" class="mr-2" @click="editItem(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      headers: [
        { text: "id", align: "start", sortable: false, value: "id" },
        { text: "Date", value: "createdAt" },
        { text: "Status", value: "status" },
        { text: "Total", value: "totalAmount" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      search: "",
      totalRecords: 0,
      orders: {
        records: [],
        count: 0,
      },
      isLoading: false,
    };
  },
  apollo: {
    orders: {
      query: gql`
        query Orders($limit: Int!, $skip: Int!, $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
              status
              payment
            }
            count
          }
        }
      `,
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result() {},
    },
  },
  methods: {
    editItem(item) {
      this.$router.push(`/admin/order/${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
